import { createContext } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";

import Root from "../../routes/Root/Root";
import Team from "../../routes/Team/Team";
import Contact from "../../routes/Contact/Contact";
import Imprint from "../../routes/Imprint/Imprint";
import Privacy from "../../routes/Privacy/Privacy";

import "./App.scss";

function App() {
  return (
    <Router>
      <div className="c-app">
        <header className="c-app__header">
          <Logo />
        </header>
        <main className="c-app__content">
          <Routes>
            <Route path="/" element={<Root />} />
            <Route path="/team" element={<Team />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/datenschutz" element={<Privacy />} />
          </Routes>
        </main>
        <footer className="c-app__footer">
        </footer>
        <div className="c-app__menu">
          <Menu />
        </div>
      </div>
    </Router>
  );
}

export default App;
