import React, { useState } from 'react';

import "./Input.scss";

export default function Input({ type = "text", name, label, required }) {
  const [filled, setFilled] = useState(false);

  const onBlur = (e) => {
    setFilled(e.target.value.length > 0)
  }

  return (
    <div className={`c-input c-input--${type} c-input--${filled ? 'filled' : 'empty'}`}>
      <label
        dangerouslySetInnerHTML={{ __html: label }}
        className={'c-input__label'}
        htmlFor={name}>
      </label>
      <input
        id={name}
        type={type}
        name={name}
        required={required}
        className={'c-input__field'}
        onChange={() => setFilled(true)}
        onFocus={() => setFilled(true)}
        onBlur={(event) => onBlur(event)}
      />
    </div>
  );
};