import React, { useEffect, useState } from 'react';

import Gallery from '../../components/Gallery/Gallery';
import { getMembers } from '../../services/CmsService';

import '../styles/Route.scss';

export default function Team() {
  const [members, setMembers] = useState();

  useEffect(() => {
    getMembers()
      .then(data => setMembers(data))
      .catch(error => {});
  }, [])

  return (
    <div className={`c-team c-route c-route--team`}>
      <div className="c-route__sections">
        <section className="c-route__section c-route__section">
          <h1>Team</h1>
          {Array.isArray(members) && <Gallery size={'small'} data={members} showPagination={false} className='c-gallery--team' />}
        </section>
      </div>
    </div>
  );
}
