import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import "./Menu.scss";

export default function Menu() {
  const [showMenu, setshowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setshowMenu(false);
  }, [location]);

  const menuItems = [
    {
      title: "Team",
      href: "/team"
    },
    {
      title: "Kontakt",
      href: "/kontakt"
    },
    {
      title: "Impressum",
      href: "/impressum"
    }
  ];

  return (
    <nav className={`c-menu c-menu--${showMenu ? 'active' : 'inactive'}`}>
      <div className={`c-menu__toggle`} onClick={() => setshowMenu(!showMenu)}>Menü</div>
      <div className={`c-menu__backdrop`} onClick={() => setshowMenu(false)}></div>
      <ul className={`c-menu__list`}>
        {menuItems.map((item, index) => (
          <li key={index} className={`c-menu__item`}>
            <a href={item.href}
              className={`c-menu__link`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setshowMenu(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });

                setTimeout(() => {
                  navigate(item.href);
                }, 500);
              }}>
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
