import React from 'react';

import Form from '../../components/Form/Form';

import '../styles/Route.scss';

export default function Contact() {
  return (
    <div className={`c-contact c-route c-route--contact`}>
      <div className="c-route__sections">
        <section className="c-route__section c-route__section">
          <h1>Kontakt</h1>
          <Form />
        </section>
      </div>
    </div>
  );
}
