import React from "react";
import logo from "./images/logo.svg";

import { useNavigate } from "react-router-dom";

import "./Logo.scss";

export default function Logo() {
  const navigate = useNavigate();

  return (
    <div className={`c-logo`}
      onClick={() => {
        navigate('/');
      }}>
      <img src={logo} alt="logo" />
    </div>
  );
}
