import React, { useState } from 'react';

import "./TextArea.scss";

export default function TextArea({ name, label, required }) {
  const [filled, setFilled] = useState(false)

  const onBlur = (e) => {
    setFilled(e.target.value.length > 0)
  }

  const onFocus = () => {
    setFilled(true)
  }

  return (
    <div className={`c-textarea c-textarea--${filled ? 'filled' : 'undefined'}`}>
      <label htmlFor={name} className={'c-textarea__label'}>{label}</label>
      <textarea
        id={name}
        name={name}
        className={'c-textarea__field'}
        required={required}
        onChange={() => setFilled(true)}
        onFocus={() => onFocus()}
        onBlur={onBlur}
        rows={4}
      />
    </div>
  );
};