import React, { useState, useEffect } from 'react';

import { getPrivacyPolicy } from '../../services/CmsService';

import '../styles/Route.scss';

export default function Privacy() {
  const [policy, setPolicy] = useState();

  useEffect(() => {
    getPrivacyPolicy()
      .then(data => {
        if (data.fields.content.content) {
          setPolicy(data.fields.content.content);
        }
      })
      .catch(error => {console.error(error)});
  }, [])

  return (
    <div className={`c-privacy c-route c-route--privacy`}>
      <div className="c-route__sections">
        <section className="c-route__section c-route__section">
          <h1>Datenschutzhinweise</h1>
          {policy && policy.map((item) => {
            return item.content.map((text) => {
              return <p dangerouslySetInnerHTML={{ __html: text.value }}></p>
            })
          })}
        </section>
      </div>
    </div>
  );
}
