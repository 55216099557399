import React, { useEffect, useState } from "react";

import "./Gallery.scss";

const autoplayDuration = 5000;

export default function Gallery({ data, size = "medium", showPagination = true, className = 'c-gallery--default' }) {
  const [currentImage, setCurrentImage] = useState(false);
  const [autoplay, setAutoplay] = useState(false);
  const [ready, setReady] = useState(false);
  const timeoutRef = React.useRef(null);

  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.src;
        loadImg.onload = () => resolve(image.url);
        loadImg.onerror = err => reject(err);
      })
    }

    Promise.all(data.map(item => loadImage(item.image)))
      .then(() => setReady(true))
      .catch(err => console.log("Failed to load images", err))
  }, []);

  useEffect(() => {
    if (ready) {
      setCurrentImage(0);
      setAutoplay(true);
    }
  }, [ready]);

  const resetTimeout = () => { if (timeoutRef.current) clearTimeout(timeoutRef.current) }
  useEffect(() => {
    if (!autoplay) return;

    resetTimeout();
    timeoutRef.current = setTimeout(() => next(), autoplayDuration);
    return () => {
      resetTimeout();
    };
  }, [currentImage]);

  const prev = () => {
    if (!ready || currentImage === null) return;
    setCurrentImage(currentImage === 0 ? data.length - 1 : currentImage - 1);
  };

  const next = () => {
    if (!ready || currentImage === null) return;
    setCurrentImage(currentImage + 1 === data.length ? 0 : currentImage + 1);
  };

  const itemClass = (index) => {
    return `c-gallery__item c-gallery__item--${index === currentImage ? 'active' : 'inactive'}`;
  };

  return (
    <div className={`c-gallery ${className} c-gallery--${size} c-gallery--${ready ? 'ready' : 'loading'}`}>
      <div className={`c-gallery__loading`}>...</div>
      <div className={`c-gallery__inner`}>
        <div className={`c-gallery__images`}>
          {data.map((item, index) => (
            <div key={index} className={itemClass(index)} onClick={(e) => {
              setAutoplay(false);
              
              const x = e.pageX - e.currentTarget.offsetLeft;
              const clickedLeft = x < (window.innerWidth / 2);

              if (clickedLeft) {
                prev();
              } else {
                next();
              }
            }}>
              <div className="c-gallery__item__inner">
                {item.image.src && item.image.alt && 
                  <div className="c-gallery__item__image">
                    <img src={item.image.src} alt={item.image.alt} />
                  </div> 
                }
                {item.description && <div className={`c-gallery__item__description`} dangerouslySetInnerHTML={{ __html: item.description }}></div>}
              </div>
            </div>
          ))}
        </div>
        {showPagination && (
          <div className={`c-gallery__pagination`}>
            <div className={`c-gallery__pagination__current`}>{currentImage + 1}</div>
            <div className={`c-gallery__pagination__page-count`}>{data.length}</div>
          </div>
        )}
      </div>
    </div>
  );
}
