import React, { useEffect, useState } from 'react';

import Gallery from '../../components/Gallery/Gallery';
import { getProjects } from '../../services/CmsService';

import '../styles/Route.scss';

export default function Root() {
  const [images, setImages] = useState();

  useEffect(() => {
    getProjects()
      .then(data => setImages(data))
      .catch(error => {});
  }, [])

  return (
    <div className={`c-root c-route c-route--root`}>
      <div className="c-route__sections">
        <section className="c-route__section c-route__section--center">
          {Array.isArray(images) && <Gallery data={images} className={'c-gallery--root'} />}
        </section>
      </div>
    </div>
  );
}
