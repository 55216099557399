const contentful = require('contentful');
const client = contentful.createClient({
  space: 'aki5kg9gut37',
  accessToken: '44pCckDTqcOqLVVlUA6WvIxwZ1s8Nrlza3qZu0zG6CQ',
});

export const getProjects = async () => {
  return client
    .getEntry('5ekyEnwtnFyug2kPC6nJbi')
    .then((entry) => {
      
      var data = [];
      entry.fields.images.map((item) => {
        data.push({
          image: {
            src: item.fields.file.url,
            alt: item.fields.title
          }
        })
      })

      return data;
    })
    .catch((err) => {
      console.log(err)}
    )
};

export const getMembers = async () => {
  return client
    .getEntry('1pVbZjwtkS1M7U2HoE9Xc7')
    .then((entry) => {
      console.log(entry);

      var data = [];
      entry.fields.images.map((item) => {
        console.log(item.fields.description);
        const description = item.fields.description ? 
          item.fields.description.replaceAll('\n', '<br>') : null;

        data.push({
          description: description,
          image: {
            src: item.fields.file.url,
            alt: item.fields.title
          }
        })
      })

      return data;
    })
    .catch((err) => {
      console.log(err)}
    )
};

export const getImprint = async () => {
  return client
    .getEntry('8agdvnXSssIxZCUZFw1qp')
    .then((entry) => {
      return entry;
    })
    .catch((err) => {
      console.log(err)}
    )
};

export const getPrivacyPolicy = async () => {
  return client
    .getEntry('8agdvnXSssIxZCUZFw1qp')
    .then((entry) => {
      return entry;
    })
    .catch((err) => {
      console.log(err)}
    )
};