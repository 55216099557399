import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import Input from "./Input.js";
import TextArea from "./TextArea.js";

import "./Form.scss";

export default function ContactForm() {
  const form = useRef(null);
  const [state, setState] = useState('undefined');

  const sendEmail = (e) => {
    e.preventDefault();

    setState('loading');
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (form.current) {
      emailjs.sendForm(
        'service_91ghuw9',
        'template_eh54yr7',
        form.current,
        '9o8mgaBtyJVs2eHKZ'
      )
        .then((result) => {
          console.log(result.text);

          if (result.text === "OK") {
            setState('success');
          } else {
            setState('undefined');
          }
        }, (error) => {
          console.log(error.text);
          setState('error');
        });
    }
  };

  const successMessage = "Vielen Dank für Ihre Anfrage. Wir werden uns so schnell wie möglich bei Ihnen melden.";
  const errorMessage = `Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder schreiben Sie uns eine Mail an <a href="mailto:info@winkler-arch.de">info@winkler-arch.de</a>`;
  const privacyDisclaimer = `
    Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage 
    gespeichert werden. Mehr zu unseren Datenschutzbestimmungen finden Sie <a target="_blank" href="/datenschutz">hier.</a>
  `;

  return (
    <form className={`c-form c-form--${state}`} ref={form} onSubmit={sendEmail}>
      <div className={'c-form__message'}>
        {state == 'error' ? errorMessage : successMessage}
      </div>
      <div className={`c-form__fields`}>
        <Input required={true} type="text" name="from_name" label="Name" />
        <Input required={true} type="email" name="email" label="E-Mail" />
        <TextArea required={true} name="message" label="Nachricht" />
        <Input
          required={true}
          type="checkbox"
          name="terms"
          label={privacyDisclaimer} />
        <input type="submit" value="Senden" />
      </div>
    </form>
  );
};